@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');

:root {
  --primary-color: #1e88e5;
  --secondary-color: #ff9800;
  --accent-color: #4caf50;
  --background-color: #f8f9fa;
  --white-color: #ffffff;
  --text-color: #333333;
  --light-text-color: #666666;
  --dark-text-color: #212121;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  overflow-x: hidden;
  font-family: 'Noto Sans JP', sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
  line-height: 1.6;
}

body {
  min-height: 100vh;
  position: relative;
}

a {
  text-decoration: none;
  color: var(--primary-color);
  transition: color 0.3s ease;
}

a:hover {
  color: var(--secondary-color);
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  min-height: calc(100vh - 70px - 200px);
  width: 100%;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

.section {
  padding: 80px 0;
}

@media (max-width: 768px) {
  main {
    min-height: calc(100vh - 60px - 180px);
  }
  
  .container {
    padding: 0 15px;
  }
  
  html, body {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0 10px;
  }
  
  html, body {
    font-size: 13px;
  }
}

@viewport {
  width: device-width;
  zoom: 1.0;
}

@-ms-viewport {
  width: device-width;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #1976d2;
}

.btn {
  display: inline-block;
  padding: 12px 25px;
  border-radius: 30px;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.btn-primary:hover {
  background-color: #1976d2;
  color: var(--white-color);
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.btn-secondary:hover {
  background-color: #f57c00;
  color: var(--white-color);
}

.text-center {
  text-align: center;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 3rem;
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-3 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-5 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.bg-white {
  background-color: var(--white-color);
}

.rounded {
  border-radius: 8px;
}

.shadow {
  box-shadow: 0 5px 15px var(--shadow-color);
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.overflow-hidden {
  overflow: hidden;
}

.text-white {
  color: var(--white-color);
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-light {
  font-weight: 300;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-decoration-none {
  text-decoration: none;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-50 {
  opacity: 0.5;
}

.transition {
  transition: all 0.3s ease;
}

.hover-scale {
  transition: transform 0.3s ease;
}

.hover-scale:hover {
  transform: scale(1.05);
}

.hover-shadow {
  transition: box-shadow 0.3s ease;
}

.hover-shadow:hover {
  box-shadow: 0 10px 20px var(--shadow-color);
}

.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.gap-1 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-5 {
  gap: 3rem;
}

@media (max-width: 992px) {
  .grid-cols-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .grid-cols-3 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .grid-cols-2, .grid-cols-3, .grid-cols-4 {
    grid-template-columns: 1fr;
  }
}

/* 背景に散りばめる図形のスタイル */
.shape-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.05;
  pointer-events: none;
}

.shape {
  position: absolute;
  opacity: 0.25;
}

.shape-circle {
  border-radius: 50%;
  background-color: var(--primary-color);
}

.shape-triangle {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 50px solid var(--secondary-color);
}

.shape-square {
  background-color: var(--accent-color);
}

/* アニメーション */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.8s ease forwards;
}

.delay-1 {
  animation-delay: 0.2s;
}

.delay-2 {
  animation-delay: 0.4s;
}

.delay-3 {
  animation-delay: 0.6s;
} 