:root {
  --primary-color: #1e88e5; /* ロゴの青色をベースにした色 */
  --secondary-color: #ff9800; /* アクセントのオレンジ色 */
  --accent-color: #4caf50; /* アクセントの緑色 */
  --background-color: #f5f9ff; /* 薄い青色の背景 */
  --text-color: #333333; /* 基本テキスト色 */
  --light-text-color: #666666; /* 薄いテキスト色 */
  --white-color: #ffffff; /* 白色 */
  --shadow-color: rgba(0, 0, 0, 0.1); /* 影の色 */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: var(--primary-color);
  transition: color 0.3s ease;
}

a:hover {
  color: var(--secondary-color);
}

button {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.section {
  padding: 60px 0;
}

.section-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 40px;
  color: var(--primary-color);
}

.btn {
  display: inline-block;
  padding: 12px 24px;
  border-radius: 30px;
  font-weight: 700;
  text-align: center;
  background-color: var(--primary-color);
  color: var(--white-color);
  box-shadow: 0 4px 6px var(--shadow-color);
}

.btn:hover {
  background-color: #1976d2; /* 少し暗い青色 */
  transform: translateY(-2px);
  box-shadow: 0 6px 8px var(--shadow-color);
  color: var(--white-color);
}

.btn-secondary {
  background-color: var(--secondary-color);
}

.btn-secondary:hover {
  background-color: #f57c00; /* 少し暗いオレンジ色 */
}

/* レスポンシブ対応 */
@media (max-width: 768px) {
  .section {
    padding: 40px 0;
  }
  
  .section-title {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 1.8rem;
  }
  
  .btn {
    padding: 10px 20px;
  }
} 